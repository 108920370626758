import clsx from 'clsx';
import React from 'react';

interface INeonLine {
  className?: string;
  vertical?: boolean;
}

const NeonLine = ({ vertical, className }: INeonLine) => {
  return (
    <div
      className={clsx(
        'pointer-events-none relative',
        vertical ? 'h-full w-[4px] grow sm:w-[6px]' : 'h-[4px] w-full',
        className
      )}
    >
      <div
        className={clsx(
          'absolute left-[0px] top-0 w-full shadow-[0px_1px_9px_3px] shadow-neonBlue-500',
          vertical ? 'h-full w-[4px] sm:w-[6px]' : 'h-[4px] w-full'
        )}
      />
      <div
        className={clsx(
          'absolute left-0 top-0  border-white',
          vertical
            ? 'h-full w-[4px] border-l-4 sm:w-[6px] sm:border-l-6'
            : 'h-[4px] w-full border-t-4 sm:h-[6px] sm:border-t-3'
        )}
      />
      <div
        className={clsx(
          'absolute left-[0px] top-0 w-full shadow-[inset_0px_0px_2.5px_1.2px] shadow-blueAlt-500/80',
          vertical ? 'h-full w-[4px] sm:w-[6px]' : 'h-[4px] w-full'
        )}
      />
    </div>
  );
};

export default NeonLine;
