import clsx from 'clsx';
import React from 'react';

import NeonBorder from '../NeonBorder/NeonBorder';
import type { ISmartLink } from '../SmartLink';
import SmartLink from '../SmartLink';
import Spinner from '../Spinner/Spinner';

export interface IButton extends ISmartLink {
  innerClassName?: string;
  borderClassName?: string;
  size?: keyof typeof sizeMap;
  loading?: boolean;
  color?: 'red' | 'purple';
}

const sizeMap = {
  xs: 'px-4 py-1 text-xs',
  sm: 'px-5 py-2.5 text-sm min-w-[120px] text-center',
  'sm-lg': 'px-5 py-2.5 md:px-7 md:py-2.5 text-sm md:text-xl text-center',
  lg: 'px-7 py-2.5 text-xl',
};

const Button = ({
  children,
  size = 'sm',
  className,
  innerClassName,
  borderClassName,
  loading,
  color,
  ...rest
}: IButton) => {
  return (
    <SmartLink className={clsx('group relative', className)} {...rest}>
      <NeonBorder style={color} className={clsx('', borderClassName)} />
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Spinner className="!h-5 !w-5 text-cream" />
        </div>
      )}
      <div
        className={clsx(
          'relative font-black text-cream transition-all',
          sizeMap[size],
          loading && 'opacity-0',
          innerClassName
        )}
      >
        {children}
      </div>
    </SmartLink>
  );
};
export default Button;
