import clsx from 'clsx';

import A11yNavigation from '@/components/Primitive/A11yNavigation';

import Footer from './Footer';
import Navigation from './Navigation';

interface IMainLayout {
  children: React.ReactNode;
  className?: string;
  withNavSpacer?: boolean;
}

const MainLayout = ({ children, withNavSpacer, className }: IMainLayout) => {
  return (
    <>
      <A11yNavigation>
        <a href="#content">Jump to main content</a>
        <a href="#navigation">Jump to primary navigation</a>
      </A11yNavigation>
      <Navigation />
      <div className="fixed left-0 top-0 z-[-1] h-full w-full">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="h-full w-full object-cover object-center"
        >
          <source src="/assets/bg-desktop.mp4" type="video/mp4" />
        </video>
      </div>
      <main
        id="content"
        className={clsx(
          'relative flex-auto',
          withNavSpacer && 'pt-[80px] md:pt-[120px]',
          className
        )}
      >
        {children}
      </main>
      <Footer />
    </>
  );
};

export default MainLayout;
