import clsx from 'clsx';
import React from 'react';

interface INeonBorder {
  className?: string;
  style?: 'red' | 'purple';
}

const colorConfig = {
  red: {
    // Main border color
    border: 'border-cream',
    // Outer blur
    borderBlur1: 'border-red-500',
    borderBlur2: 'border-cream/20',
    borderBlur3: 'border-purple-500/80',
    // Inner border shadow
    shadow: 'shadow-orange-500',
  },
  purple: {
    border: 'border-cream',
    borderBlur1: 'border-red-500',
    borderBlur2: 'border-cream/20',
    borderBlur3: 'border-purple-500/80',
    shadow: 'shadow-purple-500',
  },
};

const NeonBorder = ({ className, style = 'red' }: INeonBorder) => {
  const baseClass = clsx(
    'pointer-events-none absolute left-0 top-0 h-full w-full rounded-3xl',
    className
  );
  return (
    <>
      <span
        className={clsx(
          baseClass,
          colorConfig[style].borderBlur1,
          'border-3  blur-[3px] transition-all group-hover:blur-[10px]'
        )}
      />
      <span
        className={clsx(
          baseClass,
          colorConfig[style].borderBlur2,
          'border-3 transition-all group-hover:blur-md'
        )}
      />
      <span
        className={clsx(
          baseClass,
          colorConfig[style].borderBlur3,
          'border-3  blur-[3.75px] transition-all group-hover:blur-[6px]'
        )}
      />

      <span
        className={clsx(
          colorConfig[style].shadow,
          'absolute left-[3px] top-[3px] z-10 h-[calc(100%-6px)] w-[calc(100%-6px)] rounded-3xl shadow-[0px_0px_1px_0.45px]'
        )}
      />
      <span
        className={clsx(
          baseClass,
          colorConfig[style].shadow,
          'z-10 shadow-[inset_0px_0px_1.5px_0.45px]'
        )}
      />
      <div className={clsx(baseClass, colorConfig[style].border, 'border-3')} />
    </>
  );
};

export default NeonBorder;
