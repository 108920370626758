import { NextSeo } from 'next-seo';
import React from 'react';

interface CustomMetaProps {
  title?: string;
  slug?: string;
  description?: string;
  image?: {
    url?: string;
  };
}
export const CustomMeta = ({ title, description, image }: CustomMetaProps) => {
  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        type: 'website',
        title,
        description,
        ...(image?.url && {
          images: [{ url: image.url, width: 1200, height: 600 }],
        }),
      }}
    />
  );
};
