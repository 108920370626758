import Image from 'next/image';
import React from 'react';
import { AiOutlineInstagram } from 'react-icons/ai';
import { BsFacebook } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';

import logoImg from '@/assets/images/logo.png';

import Button from '../Primitive/Button/Button';
import NeonLine from '../Primitive/NeonLine/NeonLine';
import SmartLink from '../Primitive/SmartLink';

type Link = {
  label: string;
  href: string;
  external?: boolean;
};

const socialLinks = [
  {
    label: 'Instagram',
    href: 'https://www.instagram.com/portalone_official/',
    icon: (
      <div className="flex h-[35px] w-[35px] items-center justify-center rounded-full bg-cream transition-colors hover:bg-pink-500 focus:bg-pink-500">
        <AiOutlineInstagram size="1.5em" className="text-black " />
      </div>
    ),
  },
  {
    label: 'Facebook',
    href: 'https://www.facebook.com/portalonearcade/',
    icon: (
      <BsFacebook
        size="2.2em"
        className="text-cream transition-colors hover:text-pink-500 focus:text-pink-500"
      />
    ),
  },
  // {
  //   label: 'Twitter',
  //   href: 'https://twitter.com/portalonehg',
  //   icon: (
  //     <AiFillTwitterCircle
  //       size="2.6em"
  //       className="text-cream transition-colors hover:text-pink-500 focus:text-pink-500"
  //     />
  //   ),
  // },
  {
    label: 'TikTok',
    href: 'https://www.tiktok.com/@portalone_official',
    icon: (
      <div className="flex h-[35px] w-[35px] items-center justify-center rounded-full bg-cream transition-colors hover:bg-pink-500 focus:bg-pink-500">
        <FaTiktok size="1.5em" className="text-black" />
      </div>
    ),
  },
];

const primaryLinks: Link[][] = [
  [
    {
      label: 'How It Works',
      href: '/how-it-works',
    },
    {
      label: 'Hybrid Games',
      href: '/hybrid-games',
    },
    {
      label: 'The Arcade Show',
      href: '/arcade-show',
    },
    {
      label: 'About PortalOne',
      href: '/about',
    },
    {
      label: 'Play Now',
      href: '/play-now',
    },
  ],
  [
    {
      label: 'Guest Portal',
      href: '/guest-portal',
    },
    {
      label: 'Latest',
      href: '/news',
    },

    {
      label: 'FAQs',
      href: '/faqs',
    },
    {
      label: 'Contact',
      href: '/contact-us',
    },
    {
      label: 'Careers',
      href: 'https://join.portalone.com/',
      external: true,
    },
  ],
  [
    {
      label: 'Safety & Community',
      href: '/pages/safety-and-community',
    },
    {
      label: 'User Agreement',
      href: '/user-agreement',
    },
    {
      label: 'Privacy Policy',
      href: '/pages/privacy-policy',
    },
    {
      label: 'Competition Disclaimer',
      href: '/pages/competition-disclaimer',
    },
  ],
];

const Footer = () => {
  return (
    <div className="vertical-black-gradient">
      <NeonLine />
      <div className="xs:container-md mx-auto flex flex-wrap items-center justify-center gap-5 px-3 py-10 md:flex-nowrap md:gap-x-8 md:py-16">
        <div className="flex flex-col items-center justify-center space-y-4 md:shrink-0">
          <SmartLink className="hover-scale" to="/">
            <Image src={logoImg} width={120} height={120} alt="Portal one" />
          </SmartLink>
        </div>
        <div className="flex flex-col gap-4 md:order-3 md:basis-[290px]">
          <div className="flex items-center justify-center gap-2">
            {socialLinks.map((link) => (
              <SmartLink key={link.label} href={link.href} target="_blank">
                {link.icon}
              </SmartLink>
            ))}
          </div>
          <Button
            href="https://discord.com/invite/R8gecPUDrt"
            target="_blank"
            className="max-w-[220px] uppercase"
          >
            Join our Discord
          </Button>
        </div>
        <div className="mx-auto flex max-w-[300px] basis-full flex-wrap justify-center gap-8 pt-4 sm:max-w-none sm:flex-nowrap md:gap-x-12 lg:gap-x-20">
          {primaryLinks.map((column, i) => (
            <div
              className="flex flex-col justify-start gap-2 text-center md:text-left"
              key={`Col:${i}`}
            >
              {column.map((link) => (
                <SmartLink
                  to={link.href}
                  key={link.label}
                  className="block min-w-[110px] max-w-[170px] grow-0 basis-[40%] text-cream hover:underline focus:underline sm:basis-auto"
                  {...(link.external && { target: '_blank' })}
                >
                  {link.label}
                </SmartLink>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="container-md border-t border-blueAlt-700 py-3">
        <div className="flex flex-col gap-3 md:flex-row md:justify-between">
          <p className="w-full text-center text-sm text-blueAlt-700">
            © {new Date().getFullYear()} PortalOne, Inc. All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
