import { Disclosure, Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { Fragment } from 'react';
import { CgClose } from 'react-icons/cg';
import { FiMenu } from 'react-icons/fi';

import logoImg from '@/assets/images/logo.png';

import Button from '../Primitive/Button/Button';
import SmartLink from '../Primitive/SmartLink';

const navLinks = [
  {
    name: 'How it works',
    href: '/how-it-works',
  },
  {
    name: 'Hybrid games',
    href: '/hybrid-games',
  },
  {
    name: 'Shows',
    items: [
      {
        name: 'The Arcade show',
        href: '/arcade-show',
      },
      {
        name: 'Guest Portal',
        href: '/guest-portal',
      },
    ],
  },
  {
    name: 'About PortalOne',
    items: [
      {
        name: 'Our story',
        href: '/about',
      },
      {
        name: 'Latest',
        href: '/news',
      },
      {
        name: 'FAQs',
        href: '/faqs',
      },
      {
        name: 'Safety & community',
        href: '/pages/safety-and-community',
      },
    ],
  },
  {
    name: 'Contact Us',
    href: '/contact-us',
  },
  {
    name: 'Join Us',
    href: 'https://join.portalone.com/',
  },
];

const Navigation = ({ className }: { className?: string }) => {
  return (
    <Disclosure
      as="nav"
      id="#navigation"
      className={clsx(
        'vertical-black-gradient-reverse fixed z-20 w-full',
        className
      )}
    >
      {({ open }) => (
        <>
          <div className="container-md py-2">
            <div className="relative flex w-full items-center justify-between">
              <nav className="flex w-full items-center px-2 lg:px-0">
                <SmartLink to="/" title="Home" className="hover-scale shrink-0">
                  <div className="sr-only">Home</div>
                  <Image
                    className="block h-[80px] w-auto md:h-[100px]"
                    src={logoImg}
                    width={100}
                    height={100}
                    alt="Portal One"
                  />
                </SmartLink>
                <div className="hidden grow justify-center lg:flex">
                  <div className="flex space-x-4">
                    {navLinks.map((link) => {
                      if (link.items) {
                        return (
                          <Menu as="div" className="relative" key={link.name}>
                            <Menu.Button className="px-3 py-2 text-sm font-black uppercase text-cream transition-colors hover:text-pink-500">
                              {link.name}
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute left-0 top-full z-10 flex w-full min-w-[155px] flex-col space-y-1 rounded-sm bg-blackAlt/80">
                                <div className="absolute left-0 top-0 z-20 h-1 w-full rounded-t-md bg-blueAlt-500 after:absolute after:-top-2 after:right-3/4 after:-mt-1 after:border-6 after:border-transparent after:border-b-blueAlt-500" />
                                {link.items.map((item) => (
                                  <Menu.Item key={item.name}>
                                    {({ active }) => (
                                      <SmartLink
                                        to={item.href}
                                        className={clsx(
                                          'rounded-md border-transparent px-3 py-2 text-sm font-bold uppercase text-cream transition-colors hover:text-pink-500 hover:no-underline',
                                          active && 'text-pink-500'
                                        )}
                                      >
                                        {item.name}
                                      </SmartLink>
                                    )}
                                  </Menu.Item>
                                ))}
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        );
                      }
                      return (
                        <SmartLink
                          key={link.name}
                          to={link.href}
                          className="rounded-md border-transparent px-3 py-2 text-sm font-black uppercase text-cream transition-colors hover:text-pink-500 hover:no-underline"
                        >
                          {link.name}
                        </SmartLink>
                      );
                    })}
                  </div>
                </div>
              </nav>
              <div className="hidden shrink-0 lg:flex">
                <Button className="uppercase" to="/play-now">
                  Play now
                </Button>
              </div>
              <div className="flex lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md border-transparent p-2 text-cream hover:text-pink-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <CgClose className="block h-7 w-7" aria-hidden="true" />
                  ) : (
                    <FiMenu className="block h-7 w-7" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="vertical-black-gradient-reverse-full h-[calc(100vh-96px)] pb-4 lg:hidden">
            <div className="flex flex-col items-end justify-start space-y-1 px-2 pb-8 pt-2">
              {navLinks.map((link) => {
                if (link.items) {
                  return (
                    <Menu
                      as="div"
                      className="relative flex flex-col items-end"
                      key={link.name}
                    >
                      <Menu.Button className="block px-3 py-2 text-base font-black uppercase text-cream hover:text-pink-500 hover:no-underline">
                        {link.name}
                      </Menu.Button>
                      <Menu.Items className="relative left-2 z-10 flex w-full flex-col items-end space-y-2 bg-blackAlt/50">
                        {link.items.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <SmartLink
                                to={item.href}
                                className={clsx(
                                  'rounded-md border-transparent px-3 py-2 text-sm font-bold uppercase text-cream transition-colors hover:text-pink-500 hover:no-underline',
                                  active && 'text-pink-500'
                                )}
                              >
                                {item.name}
                              </SmartLink>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Menu>
                  );
                }

                return (
                  <Disclosure.Button
                    key={link.name}
                    as={Link}
                    href={link.href}
                    className="block rounded-md border-2 border-transparent px-3 py-2 text-base font-black uppercase text-cream hover:text-pink-500 hover:no-underline"
                  >
                    {link.name}
                  </Disclosure.Button>
                );
              })}
              <Button className="ml-3 uppercase" to="/play-now">
                Play now
              </Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navigation;
